import React, {
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as TimesIcon } from "../../../../assets/times-icon.svg";
import {
    Heading,
    InputWrapper,
    Paragraph,
    Wrapper,
    RateBox,
} from "./styles";
import InputAndSelect from "../../../../../../components/common/InputAndSelect";
import { Button } from "../../../../../../components/common/Button";
import {
    useAppDispatch,
    useAppSelector,
} from "../../../../../../app/hooks";
import Loader from "../../../../../../components/common/Loader";
import { getRate } from "../../../../api";
import useKlashaPayment from "../../../../../../libs/use-klasha";
import { MerchantForeignBankFundingRequest } from "../../../../redux/slice";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { useNavigate, useParams } from "react-router-dom";
import { COLLECTION_CURRENCIES } from "../../../../utils";
import debounce from 'lodash.debounce';
import { encryptAlgorithm } from "../../../../../../utils/encryptionAlgorithm";


interface Wallet {
    currency: string;
}

const Funding = () => {
    const { wallets } = useAppSelector(
        (state) => state?.wallets || {},
    );
    const { businessId } = useAppSelector(
        (state) => state.users,
    );
    const dispatch = useAppDispatch();
    const { business } = useAppSelector(
        (state) => state.settings,
    );



    const { currencyCode } = useParams();

    const navigate = useNavigate();
    const currencyToShow = currencyCode == "USD" ? "USD" : "KES"

    const [userData, setUserData] = useState({
        userAmount: 1,
        amount: null,
        destinationCurrency: null,
        selectedCurrency: currencyToShow,
        convertedAmount: null,
        rate: null,
        loading: false,
        disable: false,
        txRef: null,
    });

    const filteredWallets = wallets as Wallet[];
    const findWalletByID = wallets.find(
        (wallet) => wallet.currency === currencyCode,
    );
    
    const { onShowAlert } = useContext(
        NetworkErrorAlertContext,
    );

    const uniqueCurrencies = Array.from(
        new Set(
            filteredWallets?.map(
                (wallet) => wallet.currency,
            ),
        ),
    );

    const options = uniqueCurrencies.map((currency) => ({
        label: currency,
        value: currency,
    }));

    const collectionCurrency = Object.keys(COLLECTION_CURRENCIES).map((currencyCode) => ({
        label: COLLECTION_CURRENCIES[currencyCode].name,
        value: currencyCode,
    }));
    
   
    const filteredOptions = options?.filter(
        (option) => option.value === currencyCode
    );

    const handleAmountChange = (enteredAmount) => {
        if (enteredAmount < 1) {
            setUserData((prevData) => ({
                ...prevData,
                amount: 0,
            }));
        } else {
            setUserData((prevData) => ({
                ...prevData,
                amount: enteredAmount,
            }));
        }
    };

    const handleSelectedCurrency = (selectedCurrency) => {
        setUserData((prevData) => ({
            ...prevData,
            destinationCurrency: selectedCurrency,
        }));
    };

    const fetchData = async (amount, selectedCurrency, destinationCurrency) => {
        setUserData((prevData) => ({
            ...prevData,
            loading: true,
        }));
        try {
            const result = await getRate({
                provider: "BAAS",
                amount: amount,
                destinationCurrency: selectedCurrency,
                sourceCurrency: destinationCurrency,
                source: "checkout"
            });
            const prefix = "K-funding-";
            const maxLength = 25;
            const randomId = uuidv4().replace(/-/g, "");            
            const requiredLength =
                maxLength - prefix.length;
            const trimmedRandomId = randomId.substring(
                0,
                requiredLength,
            );
            const transactionId = `${prefix}${trimmedRandomId}`;
            setUserData((prevData) => ({
                ...prevData,
                convertedAmount: result.amount,
                rate: Number(1 / result.rate).toFixed(2),
                loading: false,
                txRef: transactionId,
            }));
        } catch (error) {
            setUserData((prevData) => ({
                ...prevData,
                loading: false,
                txRef: null,
            }));
            console.error(error);
        }
    };

    const debouncedFetchData = useCallback(debounce((amount, selectedCurrency, destinationCurrency) => {
        if (amount !== null && amount !== "" && selectedCurrency !== null && destinationCurrency !== null) {
            fetchData(amount, selectedCurrency, destinationCurrency);
        } else {
            setUserData((prevData) => ({
                ...prevData,
                convertedAmount: "",
                rate: 0,
                loading: false
            }));
        }
    }, 2000), []);

    useEffect(() => {
        debouncedFetchData(userData.amount, userData.selectedCurrency, userData.destinationCurrency);
    }, [userData.amount, userData.selectedCurrency, userData.destinationCurrency, debouncedFetchData]);

    useEffect(() => {
        debouncedFetchData(userData.amount, userData.selectedCurrency, userData.destinationCurrency);
    }, [userData.amount, userData.selectedCurrency, userData.destinationCurrency, debouncedFetchData]);


    const sendFundingRequest = async () => {
            
                setUserData((prevData) => ({
                    ...prevData,
                }));

                const formData = {
                    currency: userData.selectedCurrency,
                    fundAmount: parseFloat(userData?.amount),
                    creditStatus: "PENDING",
                    reference: userData.txRef,
                    localCurrency: userData.destinationCurrency,
                    localFundAmount: userData.convertedAmount,
                    rate: userData.rate,
                    walletId: findWalletByID?.id,
                };

                const encryptedData = encryptAlgorithm(formData);

                const payload = {
                    message: encryptedData,
                };


                const action = await dispatch(
                    MerchantForeignBankFundingRequest({message: payload}),
                );

                if (
                    MerchantForeignBankFundingRequest.fulfilled.match(
                        action,
                    )
                ) {
                    sendToKlasha();
                } else if (
                    MerchantForeignBankFundingRequest.pending.match(
                        action,
                    )
                ) {
                    setUserData((prevData) => ({
                        ...prevData,
                        loading: true,
                    }));
                }
               if(MerchantForeignBankFundingRequest.rejected.match(action)){
                    onShowAlert("error", "An error occured")
                }
    };

    const callWhenDone = (data) => {
        if(data.status === 'successful'){
            navigate(`/wallets/${currencyCode}`)
        }

    }

    useEffect(() => {
        if(!userData.amount){
            setUserData((prevData) => ({
                ...prevData,
                convertedAmount: "",
                rate: ""
            }));

        }
    }, [userData.amount])


    const initializePayment = useKlashaPayment({
        isTestMode: process.env.REACT_APP_ENV === "dev",
        email: business.email,
        phone_number: business.phone,
        merchantKey:
            "GByi/gkhn5+BX4j6uI0lR7HCVo2NvTsVAQhyPko/uK4=",
        businessId: businessId?.toString(),
        amount: parseFloat(userData.amount),

        destinationCurrency: userData.destinationCurrency,
        sourceCurrency: userData.selectedCurrency,
        productType: currencyCode === "USD" ?  "BAAS" : "foreignAccountFunding",
        tx_ref: userData.txRef,
        fullname: business.name,
        kit: {
            currency: userData.selectedCurrency,
            tx_ref: userData.txRef,
            paymentType: "foreignAccountFunding",
            fullname: business?.name,
            email: business.email,
            phone_number: business.phone,
            total: parseFloat(userData.convertedAmount),
            callBack: callWhenDone,
        },
        paymentDescription: "",
        });

    const sendToKlasha = () => {
        setUserData((prevData) => ({
            ...prevData,
            loading: true,
        }));

        setTimeout(async () => {
            initializePayment();
            setUserData((prevData) => ({
                ...prevData,
                loading: false,

            }));
        }, 4000)


        
    }
        

    return (
        <Wrapper>
            <Loader isLoading={userData.loading} />
            <Heading>How much do you want to fund?</Heading>
            <Paragraph>
            Your balance will be displayed in your wallet once your transaction has been received
            </Paragraph>
            <InputWrapper>
                <InputAndSelect
                    options={filteredOptions}
                    selectValue={currencyToShow}
                    placeholder=""
                    amountValue={userData.amount}
                    onAmountChange={handleAmountChange}
                    width="100%"
                />

                <div className="rate-box">
                    <RateBox>
                        <span className="fee-box">
                            <TimesIcon />
                            <span>
                            {userData.rate
                                ? userData.rate
                                : 0}{" "}
                            </span>
                        </span>

                        <span>Exchange rate</span>
                    </RateBox>
                </div>

                <InputAndSelect
                    options={collectionCurrency}
                    amountValue={userData.convertedAmount}
                    onSelectChange={handleSelectedCurrency}
                    readOnly
                    placeholder="Total amount to be charged"
                    width="100%"
                />

                <Button
                    label="Continue"
                    height="40px"
                    onClick={sendFundingRequest}
                    disabled={!userData.convertedAmount}
                />
            </InputWrapper>
        </Wrapper>
    );
};

export default Funding;
